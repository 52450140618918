<template>
  <div class="mt--8 pb-5 container" v-if="setting">
    <h1 class="text-center text-white">SETTING</h1>
    <b-form>
      <div class="card bg-secondary border-0">
        <b-row class="setting-edit">
          <b-col lg="10" class="mx-auto">
            <b-row class="border-bottom sticky-top bg-white page-header">
              <div class="d-flex align-items-center">
                <b-link @click="$router.go(-1)">
                  <font-awesome-icon icon="arrow-left-long" />
                </b-link>
                <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">Back</h5>
                <b-button @click.prevent="saveSettings">Update</b-button>
              </div>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  id="input-group-2"
                  label="Web Site Name"
                  label-for="sitename">
                  <b-form-input
                    id="sitename"
                    name="siteName"
                    v-model="setting.websiteName"
                    type="text"
                    placeholder="Enter web site name"
                    required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <h3>Database</h3>
            <b-row>
              <b-col lg="3">
                <b-form-group
                  label="DB Name"
                  label-for="databaseDB"
                  class="mb-3">
                  <b-form-input
                    id="databaseDB"
                    name="databaseDB"
                    v-model="setting.mysql.db"
                    type="text"
                    placeholder="Enter database host"
                    required />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group
                  label="Host"
                  label-for="databaseHost"
                  class="mb-3">
                  <b-form-input
                    id="databaseHost"
                    name="databaseHost"
                    v-model="setting.mysql.host"
                    type="text"
                    placeholder="Enter database host"
                    required />
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Port" label-for="databasePort">
                  <b-form-input
                    id="databasePort"
                    name="databasePort"
                    v-model="setting.mysql.port"
                    type="number"
                    placeholder="e.g: '100.10.0.0/16'"
                    required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="databaseUsername"
                  label="Username"
                  label-for="databaseUsername">
                  <b-form-input
                    id="databaseUsername"
                    name="databaseUsername"
                    v-model="setting.mysql.username"
                    type="text"
                    placeholder="Enter database username"
                    required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="databasePassword"
                  label="Password"
                  label-for="databasePassword">
                  <b-form-input
                    id="databasePassword"
                    name="databasePassword"
                    v-model="setting.mysql.password"
                    type="text"
                    placeholder="e.g: '100.10.0.0/16'"
                    required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <h3>SMTP</h3>
              <b-row>
                <b-col lg="3">
                  <b-form-group id="smtpHost" label="Host" label-for="smtpHost">
                    <b-form-input
                      id="smtpHost"
                      name="smtpHost"
                      v-model="setting.smtp.host"
                      type="text"
                      placeholder="Enter SMTP host"
                      required />
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-form-group id="smtpPort" label="Port" label-for="smtpPort">
                    <b-form-input
                      id="smtpPort"
                      name="smtpPort"
                      v-model="setting.smtp.port"
                      type="number"
                      placeholder="Enter SMTP port"
                      required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-form-group
                    id="smtpUsername"
                    label="Username"
                    label-for="smtpUsername">
                    <b-form-input
                      id="smtpUsername"
                      name="smtpUsername"
                      v-model="setting.smtp.username"
                      type="text"
                      placeholder="Enter SMTP Username"
                      required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-form-group
                    id="smtpPassword"
                    label="Password"
                    label-for="smtpPassword">
                    <b-form-input
                      id="smtpPassword"
                      name="smtpPassword"
                      v-model="setting.smtp.password"
                      type="text"
                      placeholder="Enter SMTP password"
                      required></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3">
                  <b-form-group
                    id="smtpProtocol"
                    label="Protocol"
                    label-for="smtpProtocol">
                    <b-form-input
                      id="smtpProtocol"
                      name="smtpProtocol"
                      v-model="setting.smtp.protocol"
                      type="text"
                      placeholder="Enter SMTP Protocol"
                      required></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            <hr />
            <h3>Reports</h3>
            <b-row>
              <b-col lg="6">
                <b-form-group
                  id="sflowPort"
                  label="SFlow Port"
                  label-for="sFlowPort">
                  <b-form-input
                    id="sFlowPort"
                    name="sFlowPort"
                    v-model="setting.report.port"
                    type="number"
                    required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group
                  id="interval"
                  label="IP Statistics Interval (In milliseconds)"
                  label-for="interval">
                  <b-form-input
                    id="interval"
                    name="interval"
                    v-model="setting.report.interval"
                    type="number"
                    required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
              Report Attacks
                <b-form-checkbox
                  switch
                  name="check-button"
                  v-model="setting.report.reportAttacks" />
              </b-col>
              <b-col lg="6">
                <b-form-group
                  id="databaseReportIntervalMilliseconds"
                  label="Database Report Interval (In milliseconds)"
                  label-for="databaseReportIntervalMilliseconds">
                  <b-form-input
                    id="databaseReportIntervalMilliseconds"
                    name="databaseReportIntervalMilliseconds"
                    v-model="setting.report.databaseReportIntervalMilliseconds"
                    type="number"
                    required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Connection Limit -->

          <hr>
              <h3>Connection Limits</h3>
            <b-row>
              <b-col lg="3">
                <b-form-group 
                id="connectionInterval" 
                label="Interval in seconds" 
                label-for="connectionInterval">
                  <b-form-input
                    id="connectionInterval"
                    name="connectionInterval"
                    type="number"
                    v-model="setting.connectionLimits.interval"
                    placeholder="1 - 1000000"
                    required />
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group id="udpSourcePort" label="UDP Source Port" label-for="udpSourcePort">
                  <b-form-input
                    id="udpSourcePort"
                    name="udpSourcePort"
                    type="text"
                      v-model="setting.connectionLimits.udpSourcePorts"
                    placeholder="comma-seperated numbers"
                    required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="udpDestinationPort"
                  label="UDP Destination Port"
                  label-for="udpDestinationPort">
                  <b-form-input
                    id="udpDestinationPort"
                    name="udpDestinationPort"
                    type="text"
                    v-model="setting.connectionLimits.udpDestinationPorts"
                    placeholder="comma-seperated numbers"
                    required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="ucThreshold"
                  label="Unique Connection Threshold"
                  label-for="ucThreshold">
                  <b-form-input
                    id="ucThreshold"
                    name="ucThreshold"
                    type="number"
                    v-model="setting.connectionLimits.uniqueConnectionThreshold"
                    placeholder="1 - 1000000"
                    min="1"
                    required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group
                  id="connectionRate"
                  label="Connection Rate in seconds"
                  label-for="connectionRate">
                  <b-form-input
                    id="connectionRate"
                    name="connectionRate"
                    type="number"
                    v-model="setting.connectionLimits.connectionRate"
                    placeholder="1 - 1000000"
                    required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <hr>
              <h3>Volume Limits</h3>
            <b-row>
              <b-col lg="3">
                <b-form-group
                  id="numberOfTopIps"
                  label="Number of IPs in Top List"
                  label-for="numberOfTopIps">
                  <b-form-input
                    id="numberOfTopIps"
                    name="numberOfTopIps"
                    type="number"
                    v-model="setting.volumeLimits.ips"
                    placeholder="1 - 50"
                    required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="trackingPeriod"
                  label="Tracking Period in Seconds"
                  label-for="trackingPeriod">
                  <b-form-input
                    id="trackingPeriod"
                    name="trackingPeriod"
                    type="number"
                    v-model="setting.volumeLimits.trackingPeriod"
                    placeholder="10 - 1000000"
                    min="10"
                    required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
      </div>
    </b-form>
  </div>
</template>
<script>
import SettingService from "../../services/setting.service";
import { notify } from "../../helpers/index";
import "vue3-toastify/dist/index.css";

export default {
  name: "SettingEdit",
  
  data: function () {
    return {
      setting: null,
      sitename: "",
      databaseHost: "",
      databasePort: 0,
      databaseName: "",
      databaseUsername: "",
      databasePassword: "",
      smtpHost: "",
      smtpUsername: "",
      smtpPassword: "",
      smtpPort: "",
      smtpProtocol: "",
      sFlowPort: 6343,
      interval: 60000,
      errors: {},
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.getSetting();
    this.$title = "Eagle";
  },
  methods: {
    logOut() {
      localStorage.removeItem("user");
      this.$router.push("/");
    },
    async getSetting() {
      const url = "settings";
      try {
        const response = await SettingService.getSetting(url);
        if (response.status == 200 && response.data.websiteName) {
          this.setting = response.data;
          this.$store.dispatch("setting/setLogo", this.setting.websiteName);
          this.setTitle(this.setting.websiteName);
        }
      } catch (error) {
        this.logOut()
      }
      
    },
    async saveSettings() {
      const url = "settings";

      this.setting.volumeLimits.ips = Number(this.setting?.volumeLimits?.ips);
      this.setting.volumeLimits.trackingPeriod = Number(this.setting?.volumeLimits?.trackingPeriod);
      
      const response = await SettingService.updateSetting(
        url,
        this.setting
      );
      if (response.status == 200 && response.data.websiteName) {
        this.setting = response.data;
        this.$store.dispatch("setting/setLogo", this.setting.websiteName);
        this.setTitle(this.setting.websiteName);
        notify('Settings updated successfully')
      }
      else {
        notify('Settings update failed', 'error')

     }
    },
  },
};
</script>
<style scoped>
.bg-secondary {
  background-color: #f7fafc !important;
}
</style>
